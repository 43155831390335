import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import SEO from "../components/Seo/seo";
import Header from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
import { Helmet } from "react-helmet";

const TqContactFormPage = () => {
  const [retPageUrl, setRetPageUrl] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedRetPageUrl = localStorage.getItem("salesFormUrl");
      if (storedRetPageUrl) {
        setRetPageUrl(storedRetPageUrl);
      }
    }
  }, []);

  return (
    <>     
     <Helmet>
     <meta name="robots" content="noindex, nofollow" />
    <script >
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5VPPNG9')`}
                </script>
   
  </Helmet>
      <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5VPPNG9"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
    />,
      <Header />
      <div className="content null">
      <div className="about-text landing-intro error-full-bg">
        <SEO title="Thank you for contacting our staff" />
        <div className="not-found-page">
        <h1>Thank you for contacting our staff</h1>
        <p>
        Our staff will get back to you soon.<br></br>If you have any other queries. Please reach out to us at{" "}
              <a href="mailto:manchester@orlandoreid.co.uk">manchester@orlandoreid.co.uk</a> or call us at{" "}
              <a href="tel:+01616760099">0161 676 0099</a>
            </p>
        <Link to="/" className="primary-button btn btn-success">Home Page</Link>
          <Link to={retPageUrl} className="primary-button btn btn-default">Previous Page</Link>
        </div>
      </div>
    </div>
          {/* <Footer /> */}

    </>
  );
};

export default TqContactFormPage;
